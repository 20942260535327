import {GdprResponseModel} from "./gdpr-response.model";
import moment from "moment";

export class GdprRequestDetailsModel {
  createdAt: moment.Moment;
  expirationDate: moment.Moment;
  gdprResponse?: GdprResponseModel | null;

  constructor(createdAt: moment.Moment, expirationDate: moment.Moment, gdprResponse?: GdprResponseModel | null) {
    this.gdprResponse = gdprResponse;
    this.createdAt = moment(createdAt);
    this.expirationDate = moment(expirationDate);
  }
}
